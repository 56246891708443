import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { airbrakeClient } from "@utils/airbrake/browser";

import { Ad } from "@components/ad";
import { Header } from "@pageComponents/articles/header";
import { Blocks } from "@components/blocks";
import { Pageblock } from "@components/pageblock";
import { SponsorshipDisclaimer } from "@pageComponents/articles/sponsorship/disclaimer";
import { LatestArticles } from "@components/latestArticles";
import { NextHead } from "@components/nextHead";

import { articlePageAPI, validateArticle } from "@queries/article/single/api";
import { elsewhereDestinations } from "@constants/elsewhere-destinations/elsewhereDestinations";
import { useMonarch } from "@cohesion/monarch";
import { dangerHTML } from "@utils/danger";

const DynamicElsewhereRequest = dynamic(() =>
  import("@components/elsewhere/request").then(mod => mod.ElsewhereRequest)
);

export default function Articles({ article }) {
  const {
    title,
    excerpt,
    meta,
    schemaMarkup,
    authors,
    readtime,
    pubtime,
    updateNotice,
    breadcrumbs,
    featuredImage,
    contentBlocks,
    related,
    sponsorship,
    path,
    esid,
    places,
  } = article;

  const [primary, ...additional] = related || [];

  const monarch = useMonarch();
  const articleRef = useRef(null);
  const router = useRouter();
  const [showElsewhereRequest, setShowElsewhereRequest] = useState(false);

  const handleMonarchResponse = (err, response) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error("error during monarch callback", err);
      airbrakeClient.notify({
        error: err,
        context: {
          component:
            "pages/articles/[slug]/index.page.jsx#handleMonarchResponse",
        },
      });
      return;
    }

    if (response.Elsewhere_Widget) {
      setShowElsewhereRequest(true);
    }
  };

  useEffect(() => {
    // Ping Monarch to determine if we're showing an Elsewhere request widget
    monarch(
      "rule",
      "1a3d5839-75fc-4de5-9100-3358caf41ce8",
      {
        asPath: router.asPath,
      },
      {},
      handleMonarchResponse
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="relative" id="articleLayout">
      <NextHead image={featuredImage} metadata={meta.tags}>
        <title>{meta.title}</title>
        {meta.schema && (
          <script type="application/ld+json" {...dangerHTML(meta.schema)} />
        )}
        {schemaMarkup && (
          <script type="application/ld+json" {...dangerHTML(schemaMarkup)} />
        )}
      </NextHead>
      <Header
        {...{
          breadcrumbs,
          title,
          authors,
          readtime,
          pubtime,
          featuredImage,
          esid,
          slug: path?.alias,
          excerpt,
          sponsorship,
        }}
      />
      <div className="py-14 container xl:max-w-6xl xl:border-sand-300 xl:border xl:border-b-0 xl:border-t-0 xl:py-24">
        <div className="lg:px-24">
          <div ref={articleRef}>
            {Array.isArray(contentBlocks) && contentBlocks.length !== 0 && (
              <Blocks html={contentBlocks} />
            )}

            {updateNotice && (
              <p className="my-24 text-black-400 italic">{updateNotice}</p>
            )}
          </div>

          {sponsorship && <SponsorshipDisclaimer sponsorship={sponsorship} />}
        </div>
        <Ad
          className="mt-24"
          path="articles/bottom"
          placement="fullWidth"
          white
        />
      </div>
      {/**
       * Pulling elsewhere destinations from a hardcoded mapping
       * This is a temporary solution until we flesh out the Monarch rule more
       */}
      {showElsewhereRequest && (
        <DynamicElsewhereRequest
          elsewhereDestination={
            places.length === 1 ||
            (places.length > 0 &&
              places.every(place => place.meta.type === "Region"))
              ? elsewhereDestinations.find(
                  destination =>
                    destination.lpSlugs.includes(places[0].slug) ||
                    places[0].breadcrumb.some(breadcrumb =>
                      destination.lpSlugs.includes(breadcrumb.slug)
                    )
                )
              : null
          }
          slug={path?.alias}
        />
      )}

      {related?.length >= 3 && (
        <Pageblock className="border-t border-sand-300" inset>
          <LatestArticles
            enableFilters={false}
            title="Explore related stories"
            articles={{
              primary,
              additional,
            }}
          />
        </Pageblock>
      )}
    </div>
  );
}

export async function getServerSideProps({ query, res }) {
  const { slug, articleType = "articles", previewEnabled = false } = query;
  if (
    process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "production" &&
    !previewEnabled
  ) {
    res.setHeader("Cache-Control", "no-cache, max-age=3600, must-revalidate");
  }
  // prevent caching preview requests
  if (previewEnabled) {
    res.setHeader(
      "Cache-Control",
      "no-cache, no-store, max-age=0, must-revalidate"
    );
  }
  const url = `/${articleType}/${slug}`;

  // only validate if request is not for a preview
  if (!previewEnabled) {
    /**
     * check for file extension and exit early when true
     * prevents running a validation lookup on erroneous routes
     */
    if (!slug || slug.match(/\.([^.]*)$/)) {
      return {
        notFound: true,
      };
    }

    const { isValid, redirect } = await validateArticle({
      url,
    });

    if (!isValid) {
      return {
        notFound: true,
      };
    }

    if (redirect) {
      return {
        redirect: {
          destination: redirect,
          permanent: true,
        },
      };
    }
  }

  const pageData = await articlePageAPI({
    slug,
    previewEnabled,
    url,
  });

  if (previewEnabled && pageData === null) {
    return {
      notFound: true,
    };
  }

  const { adTargeting, ...article } = pageData;

  return {
    props: {
      article,
      adTargeting,
      /**
       * A last second design change complicated the presentational aspects of
       * our navbar, so this class routing is an interim fix while we reconsider
       * how we want to handle more robust support for the element
       */
      navbarClassName: "bg-blue-100",
    },
  };
}

Articles.propTypes = {
  article: PropTypes.shape({
    esid: PropTypes.string.isRequired,
    path: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    contentBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({}),
        type: PropTypes.string,
      })
    ),
    meta: PropTypes.shape({
      title: PropTypes.string,
      schema: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    schemaMarkup: PropTypes.string,
    featuredImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      caption: PropTypes.string,
    }).isRequired,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    readtime: PropTypes.number.isRequired,
    pubtime: PropTypes.string.isRequired,
    updateNotice: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
    related: PropTypes.arrayOf(PropTypes.shape({})),
    sponsorship: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
    places: PropTypes.arrayOf(
      PropTypes.shape({
        meta: PropTypes.shape({}),
        slug: PropTypes.string,
        title: PropTypes.string,
        breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
      })
    ).isRequired,
  }).isRequired,
};
